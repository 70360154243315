.App {
  text-align: center;
  overflow-x: hidden;
}

.iframeContainer {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: #f2f2f2;
}

.iframeContainer iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.sticker {
  position: absolute;
  top: 0;
  left: 0;
  height: 56px;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  /* box-shadow: 0px 24px 40px rgb(26 26 26 / 16%); */
}

.stickerLogo {
  right: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
}
.iconContainer {
  display: flex;
  left: 20px;
  gap: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
}
.wind {
  transform: rotate(-180deg);
}

@media only screen and (min-width: 601px) {
  .sticker {
    height: 64px;
  }

  .stickerLogo {
    right: 40px;
    height: 40px;
  }
  .iconContainer {
    font-size: 2rem;
  }
}
